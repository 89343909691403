export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'หน้าหลัก',
        permissionName: 'Home',
        to: '/',
        fontIcon: 'fas fa-tv icon-circle',
        roles: ['Company', 'Agent'],
        items: []
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงานการแทง',
        permissionName: 'BetReport',
        route: '/setting-report',
        fontIcon: 'fas fa-chart-bar icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'รอผลเดิมพัน',
            permissionName: 'WaitReport',
            fontIcon: 'fas fa-angle-right',
            to: '/setting-report/wait'
          },
          {
            name: 'ดูของรวม/คาดคะเน ได้-เสีย',
            permissionName: 'WinLose',
            fontIcon: 'fas fa-angle-right',
            to: '/setting-report/win-lose',
            mTypes: ['single']
          },
          {
            name: 'ดูของรวม/คาดคะเน ได้-เสีย',
            permissionName: 'WinLose',
            fontIcon: 'fas fa-angle-right',
            to: '/setting-report/sets-overall',
            mTypes: ['lottoset']
          },
          {
            name: 'ดูของสมาชิก/ประเภท',
            permissionName: 'MemberReport',
            fontIcon: 'fas fa-angle-right',
            to: '/setting-report/member',
            mTypes: ['single']
          },
          {
            name: 'ดูของสมาชิก',
            permissionName: 'MemberReport',
            fontIcon: 'fas fa-angle-right',
            to: '/setting-report/sets-member',
            mTypes: ['lottoset']
          },
          {
            name: 'รายการโพย/ยกเลิกโพย',
            permissionName: 'TicketReport',
            fontIcon: 'fas fa-angle-right',
            to: '/setting-report/tickets'
          },
          {
            name: 'เลขปิดรับ/เลขอั้น',
            permissionName: 'LimitNumbers',
            fontIcon: 'fas fa-angle-right',
            to: '/setting-report/limit',
            mTypes: ['single']
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงาน แพ้-ชนะ',
        permissionName: 'WinLoseReport',
        route: '/win-lose-report',
        fontIcon: 'far fa-star icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'สรุปยอด แพ้-ชนะ',
            permissionName: 'MarketWinLose',
            fontIcon: 'fas fa-angle-right',
            to: '/win-lose-report/market'
          },
          {
            name: 'แพ้-ชนะ สมาชิก/ประเภท',
            permissionName: 'MemberTypeWinLose',
            fontIcon: 'fas fa-angle-right',
            to: '/win-lose-report/member',
            mTypes: ['single']
          }, {
            name: 'แพ้-ชนะ สมาชิก/ประเภท',
            permissionName: 'MemberTypeWinLose',
            fontIcon: 'fas fa-angle-right',
            to: '/win-lose-report/sets',
            mTypes: ['lottoset']
          },
          {
            name: 'รายการโพยถูกรางวัล',
            permissionName: 'WonTickets',
            fontIcon: 'fas fa-angle-right',
            to: '/win-lose-report/won-tickets'
          },
          {
            name: 'ผลการออกรางวัล',
            permissionName: 'Results',
            fontIcon: 'fas fa-angle-right',
            to: '/win-lose-report/results'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่างวดหวย',
        permissionName: 'RoundSetting',
        route: '/setting-round',
        fontIcon: 'fas fa-cogs icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'ตั้งค่าอัตราจ่าย',
            permissionName: 'Payrate',
            fontIcon: 'fas fa-angle-right',
            roles: ['Company'],
            mTypes: ['single'],
            to: '/setting-round/number-rate'
          },
          {
            name: 'ตั้งค่ารับของ (เฉพาะงวด)',
            permissionName: 'RoundReceive',
            fontIcon: 'fas fa-angle-right',
            roles: ['Company', 'Agent'],
            mTypes: ['single'],
            to: '/setting-round/receive'
          },
          {
            name: 'ตั้งค่าหวยชุด (เฉพาะงวด)',
            permissionName: 'RoundReceive',
            fontIcon: 'fas fa-angle-right',
            roles: ['Company', 'Agent'],
            mTypes: ['lottoset'],
            to: '/setting-round/sets'
          },
          {
            name: 'ตั้งค่ารับของ (ตามชนิด)',
            permissionName: 'MarketReceive',
            fontIcon: 'fas fa-angle-right',
            roles: ['Company', 'Agent'],
            to: '/setting-round/market-receive'
          },
          {
            name: 'ตั้งค่ารับของ (หวยชุด)',
            permissionName: 'MarketReceive',
            fontIcon: 'fas fa-angle-right',
            roles: ['Company', 'Agent'],
            to: '/setting-round/sets-receive'
          },
          {
            name: 'บันทึกการตั้งค่า',
            permissionName: 'SettingHistory',
            fontIcon: 'fas fa-angle-right',
            roles: ['Company', 'Agent'],
            to: '/setting-round/history'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'บัญชีการเงิน',
        permissionName: 'Finance',
        route: '/account-report',
        fontIcon: 'fas fa-dollar-sign icon-circle',
        roles: ['Company', 'Agent'],
        items: [{
          name: 'รายงานการเงิน',
          permissionName: 'FinanceReport',
          fontIcon: 'fas fa-angle-right',
          to: '/account-report/finance'
        }, {
          name: 'การเงินสมาชิก',
          permissionName: 'FinanceMember',
          fontIcon: 'fas fa-angle-right',
          to: '/account-report/finance-member'
        }, {
          name: 'ประวัติการเข้าใช้งาน',
          permissionName: 'AccountLog',
          fontIcon: 'fas fa-angle-right',
          to: '/account-report/log'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการสมาชิก',
        permissionName: 'ManageAccount',
        route: '/manage-account',
        fontIcon: 'fas fa-users icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'รายชื่อสมาชิก',
            permissionName: 'MemberSetting',
            fontIcon: 'fas fa-angle-right',
            to: '/manage-account/member'
          },
          {
            name: 'เพิ่มเมมเบอร์',
            permissionName: 'CreateMember',
            fontIcon: 'fas fa-angle-right',
            to: '/manage-account/create/member'
          },
          {
            name: 'เพิ่มเอเย่นต์',
            permissionName: 'CreateAgent',
            fontIcon: 'fas fa-angle-right',
            to: '/manage-account/create/agent'
          },
          {
            name: 'รายชื่อที่ถูกระงับ',
            permissionName: 'BlockMember',
            fontIcon: 'fas fa-angle-right',
            to: '/manage-account/block'
          },
          {
            name: 'ตั้งค่าสมาชิกใหม่',
            permissionName: 'NewMemberSetting',
            fontIcon: 'fas fa-angle-right',
            to: '/manage-account/setting'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่าสมาชิก',
        permissionName: 'AccountSetting',
        route: '/account-setting',
        fontIcon: 'fas fa-users-cog icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'เปิด/ปิด หวย',
            permissionName: 'MemberMarket',
            fontIcon: 'fas fa-angle-right',
            to: '/account-setting/group'
          },
          {
            name: 'แบ่งหุ้น/เก็บของ',
            permissionName: 'MemberSharing',
            fontIcon: 'fas fa-angle-right',
            to: '/account-setting/sharing'
          },
          {
            name: 'เปิด/ปิด/ตั้งค่า อัตราจ่าย',
            permissionName: 'MemberPayout',
            fontIcon: 'fas fa-angle-right',
            to: '/account-setting/payout'
          },
          {
            name: 'ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข',
            permissionName: 'MemberBetLimit',
            fontIcon: 'fas fa-angle-right',
            to: '/account-setting/bet'
          },
          {
            name: 'ตั้งค่าหวยชุด',
            permissionName: 'MemberSets',
            fontIcon: 'fas fa-angle-right',
            to: '/account-setting/sets'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ผู้ช่วย',
        route: '/assistant',
        fontIcon: 'fas fa-people-arrows icon-circle',
        roles: ['Company', 'Agent'],
        userRoles: ['Owner'],
        items: [{
          name: 'รายชื่อผู้ช่วย',
          fontIcon: 'fas fa-angle-right',
          to: '/assistant/all'
        }, {
          name: 'เพิ่มผู้ช่วย',
          fontIcon: 'fas fa-angle-right',
          to: '/assistant/create'
        }]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'ออกจากระบบ',
        to: '/logout',
        fontIcon: 'fas fa-sign-out-alt icon-circle',
        items: []
      }
    ]
  }
]
